/* Sizes */

/* Sizes */

.no-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 570px;
  padding-left: 57px;
}

.no-page-wrapper__left,
.no-page-wrapper__right {
  width: 50%;
}

.no-page-wrapper__title {
  margin-bottom: 60px;
  max-width: 100%;
}

.no-page-wrapper__under-title {
  margin-bottom: 50px;
}

.no-page-wrapper__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 231px;
  height: 60px;
  margin: 0;
  background-color: #212121;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
}

.no-page-wrapper__btn span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.no-page-wrapper__btn:hover {
  background-color: transparent;
  border-color: #212121;
}

.no-page-wrapper__btn:hover span {
  color: #212121;
}

.no-page-wrapper__right {
  display: none;
}

.no-page-wrapper__right .aroma-small-search {
  max-width: 498px;
  align-self: flex-start;
}

.no-page-wrapper__right .aroma-small-search input {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 22px 50px 22px 30px;
  border: 1px solid #e6e4de;
  color: #38373d;
  border-radius: 0;
}

.no-page-wrapper__right .aroma-small-search input::placeholder {
  color: #38373d;
}

.no-page-wrapper__right .aroma-small-search__srch-btn {
  height: 16px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.popular-goods {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 40px;
  text-align: center;
}

.popular-goods__title {
  margin-bottom: 40px;
}

.popular-goods__content {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.popular-goods__content:hover {
  overflow-x: visible;
}

.catalog-top__campaigne {
  position: relative;
  display: flex;
  padding: 40px 60px;
  background: #f8f8f8;
  color: #212121;
}

.catalog-top__campaigne__left {
  padding-right: 77px;
}

.catalog-top__campaigne__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 9px;
}

.catalog-top__campaigne__content-hidden {
  display: none;
  margin-top: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #212121;
}

.catalog-top__campaigne__content-hidden p {
  margin-bottom: 34px;
}

.catalog-top__campaigne .btn_expand {
  margin-top: 24px;
}

.catalog-top__campaigne .btn_expand span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
}

.catalog-top__campaigne .btn_expand span::after {
  content: "";
  display: block;
  width: 12px;
  height: 14px;
  background: url(../../../images/icons/arrow-grey.svg) no-repeat center;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.3s ease-in;
  margin-left: 10px;
}

.catalog-top__campaigne .btn_expand.active span:after {
  background: url(../../../images/icons/arrow-grey.svg) no-repeat center;
  transform: rotate(180deg);
}

.catalog-top__campaigne__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 60px;
  text-align: start;
}

.catalog-top__campaigne__under-title {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  text-align: start;
}

.catalog-top__campaigne__timer {
  background: #ffffff;
  padding: 30px 69.5px 51px;
  border-radius: 20px;
  margin: 0 auto;
}

.catalog-top__campaigne__timer-top {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  color: #212121;
  margin-bottom: 25px;
}

.catalog-top__campaigne__timer-data {
  display: flex;
  justify-content: center;
}

.catalog-top__campaigne__timer-days,
.catalog-top__campaigne__timer-hours,
.catalog-top__campaigne__timer-minutes {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.catalog-top__campaigne__timer-separator-points {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 36px;
  width: 10px;
  margin-left: 38px;
  margin-right: 38px;
}

.catalog-top__campaigne__timer-separator-points .point {
  width: 3px;
  height: 3px;
  background: #212121;
  border-radius: 50%;
}

.catalog-top__campaigne__timer-day,
.catalog-top__campaigne__timer-hour,
.catalog-top__campaigne__timer-minute {
  color: #38373d;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
}

.catalog-top__campaigne__timer-day-name,
.catalog-top__campaigne__timer-hour-name,
.catalog-top__campaigne__timer-minute-name {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #212121;
}

.catalog-top__campaigne-block-title {
  margin-top: 80px;
  margin-bottom: 30px;
}

.catalog-top__campaigne-block-title h3 {
  color: #000000;
}

@media (min-width: 530px) {
  .popular-goods__content {
    justify-content: flex-start;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .user-content p {
    height: 255px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .no-page-wrapper__title {
    padding-bottom: 45px;
  }
}

@media (min-width: 1201px) {
  .breadcrumbs-container {
    display: none;
  }

  .no-page-wrapper__right {
    display: flex;
    justify-content: flex-end;
  }

  .catalog-top__campaigne .btn_expand {
    align-self: flex-end;
  }

  .catalog-top__campaigne .btn-goto {
    margin: 0;
  }

  .user-content p {
    margin-bottom: 80px;
    margin-top: 0;
    height: auto;
  }
}

@media (max-width: 1200px) {
  .no-page-wrapper {
    padding: 0 62px;
    margin-top: 100px;
    margin-bottom: 178px;
  }

  .no-page-wrapper__left,
  .no-page-wrapper__right {
    width: 100%;
  }

  .no-page-wrapper__title {
    margin-bottom: 100px;
  }

  .catalog-top__campaigne {
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
  }

  .catalog-top__campaigne__left {
    margin-bottom: 30px;
    padding-right: 0;
  }

  .catalog-top__campaigne__right {
    padding-top: 0;
  }

  .catalog-top__campaigne .btn_expand {
    margin-top: 30px;
  }

  .catalog-top__campaigne__title {
    text-align: center;
    height: 181px;
    margin-bottom: 30px;
  }

  .catalog-top__campaigne__under-title {
    text-align: center;
    padding-bottom: 15px;
  }

  .catalog-top__campaigne__timer {
    padding: 30px 67.5px 51px;
    max-width: 440px;
  }

  .catalog-top__campaigne__timer-top {
    margin-bottom: 31px;
  }

  .catalog-top__campaigne__timer-separator-points {
    height: 33px;
    width: 10px;
  }

  .catalog-top__campaigne-block-title {
    margin-top: 77px;
  }
}

@media (max-width: 767px) {
  .no-page-wrapper {
    margin: 64px 0 74px;
    padding: 0;
  }

  .no-page-wrapper__title {
    margin-bottom: 89px;
  }

  .no-page-wrapper__under-title {
    margin-bottom: 54px;
  }

  .no-page-wrapper__btn {
    height: 50px;
    margin: 0 auto;
  }

  .no-page-wrapper__left {
    text-align: center;
  }

  .popular-goods {
    padding: 60px 20px;
  }

  .popular-goods__title {
    margin-bottom: 30px;
  }

  .catalog-top__campaigne {
    padding-left: 23px;
    padding-right: 23px;
  }

  .catalog-top__campaigne__title {
    font-size: 26px;
    line-height: 30px;
  }

  .catalog-top__campaigne__title {
    height: auto;
    padding-bottom: 15px;
  }

  .catalog-top__campaigne__under-title {
    padding-bottom: 13px;
  }

  .catalog-top__campaigne__timer {
    max-width: 320px;
    padding: 30px 20px 48px;
  }

  .catalog-top__campaigne__timer-top {
    margin-bottom: 19px;
  }

  .catalog-top__campaigne__timer-separator-points {
    height: 27px;
    width: 7px;
    margin-left: 25px;
    margin-right: 20px;
  }

  .catalog-top__campaigne__timer-day,
  .catalog-top__campaigne__timer-hour,
  .catalog-top__campaigne__timer-minute {
    font-size: 26px;
    line-height: 30px;
  }

  .catalog-top__campaigne-block-title {
    margin-top: 30px;
  }
}

@media (max-width: 529px) {
  .popular-goods__content {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}