@import '../../uikit/vars/vars';
@import "../../uikit/mixins";

.breadcrumbs-container {
  @media (min-width: $desktop_min) {
    display: none;
  }
}

.no-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 570px;
  padding-left: 57px;

  @media (max-width: $tablet_max) {
    padding: 0 62px;
    margin-top: 100px;
    margin-bottom: 178px;
  }

  @media (max-width: $phone_max) {
    margin: 64px 0 74px;
    padding: 0;
  }

  &__left,
  &__right {
    width: 50%;

    @media (max-width: $tablet_max) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 60px;
    max-width: 100%;

    @media (min-width: $tablet_max) {
      padding-bottom: 45px;
    }

    @media (max-width: $tablet_max) {
      margin-bottom: 100px;
    }

    @media (max-width: $phone_max) {
      margin-bottom: 89px;
    }
  }

  &__under-title {
    margin-bottom: 50px;
    @media (max-width: $phone-max) {
      margin-bottom: 54px;
    }
  }

  &__btn {
    @include flex-center;
    width: 231px;
    height: 60px;
    margin: 0;

    background-color: $color_dark_grey_2;
    border: 1px solid transparent;
    transition: background-color 0.3s ease;

    span {
      @include h6;
      color: $color_white;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      transition: color 0.3s ease;
    }

    &:hover {
      background-color: transparent;
      border-color: $color_dark_grey_2;
  
      span {
        color: $color_dark_grey_2;
      }
    }

    @media (max-width: $phone-max) {
      height: 50px;
      margin: 0 auto;
    }
  }

  &__left {
    @media (max-width: $phone_max) {
      text-align: center;
    }
  }

  &__right {
    display: none;

    @media (min-width: $desktop_min) {
      display: flex;
      justify-content: flex-end;
    }

    .aroma-small-search {
      max-width: 498px;
      align-self: flex-start;

      input {
        @include txt__small-addition;
        padding: 22px 50px 22px 30px;
        border: 1px solid $color_grey_3;
        color: $color_grey;
        border-radius: 0;
        &::placeholder {
          color: $color_grey;
        }
      }
      &__srch-btn {
        height: 16px;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
      }
    }
  }
}

.popular-goods {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 40px;
  text-align: center;

  @media (max-width: $phone-max) {
    padding: 60px 20px;
  }

  &__title {
    margin-bottom: 40px;

    @media (max-width: $phone_max) {
      margin-bottom: 30px;
    }
  }

  &__content {
    display: flex;
    gap: 20px;

    margin-bottom: 40px;

    &:hover {
      overflow-x: visible;
    }

    @media (min-width: 530px) {
      justify-content: flex-start;
      overflow-x: auto;
      flex-wrap: nowrap;
    };

    @media (max-width: 529px) {
      justify-content: center;
      flex-wrap: wrap;

      margin-bottom: 30px;
    }
  }
}

// Campaigne Block
.catalog-top {
  &__campaigne {
    position: relative;
    display: flex;
    padding: 40px 60px;

    background: $color_white_smoke;
    color: $color_dark_grey_2;

    @media (max-width: $tablet_max) {
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (max-width: $phone_max) {
      padding-left: 23px;
      padding-right: 23px;
    }

    &__left {
      padding-right: 77px;
      @media (max-width: $tablet_max) {
        margin-bottom: 30px;
        padding-right: 0;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 9px;

      @media (max-width: $tablet-max) {
        padding-top: 0;
      }
    }
    &__content-hidden {
      display: none;
      margin-top: 30px;
      @include body__text;
      color: $color_dark_grey_2;

      p {
        margin-bottom: 34px;
      }
    }
    .btn_expand {
      margin-top: 24px;

      span {
        @include h6;
        color: $color_dark_grey_2;

        &::after {
          content: "";
          display: block;
          width: 12px;
          height: 14px;
          background: url(../../../images/icons/arrow-grey.svg) no-repeat center;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          transition: all 0.3s ease-in;
          margin-left: 10px;
        }
      }
      &.active {
        span:after {
          background: url(../../../images/icons/arrow-grey.svg) no-repeat center;
          transform: rotate(180deg);
        }
      }

      @media (min-width: $desktop_min) {
        align-self: flex-end;
      }

      @media (max-width: $tablet-max) {
        margin-top: 30px;
      }
    }
    &__title {
      @include h2;
      margin-bottom: 60px;
      text-align: start;
      @media (max-width: $tablet_max) {
        text-align: center;
        height: 181px;
        margin-bottom: 30px;
      }
      @media (max-width: $phone-max) {
        height: auto;
        padding-bottom: 15px;
      }
    }
    &__under-title {
      @include body__text;
      text-align: start;

      @media (max-width: $tablet-max) {
        text-align: center;
        padding-bottom: 15px;
      }

      @media (max-width: $phone_max) {
        padding-bottom: 13px;
      }
    }
    &__timer {
      background: $color_white;
      padding: 30px 69.5px 51px;
      border-radius: 20px;
      margin: 0 auto;

      @media (max-width: $tablet-max) {
        padding: 30px 67.5px 51px;
        max-width: 440px;
      }

      @media (max-width: $phone_max) {
        max-width: 320px;
        padding: 30px 20px 48px;
      }
      &-top {
        @include h6;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        text-align: center;
        color: $color_dark_grey_2;
        margin-bottom: 25px;

        @media (max-width: $tablet-max) {
          margin-bottom: 31px;
        }

        @media (max-width: $phone_max) {
          margin-bottom: 19px;
        }
      }
      &-data {
        display: flex;
        justify-content: center;
      }
      &-days,
      &-hours,
      &-minutes {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
      &-separator-points {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 36px;
        width: 10px;
        margin-left: 38px;
        margin-right: 38px;
        .point {
          width: 3px;
          height: 3px;
          background: $color_dark_grey_2;
          border-radius: 50%;
        }

        @media (max-width: $tablet_max) {
          height: 33px;
          width: 10px;
        }

        @media (max-width: $phone_max) {
          height: 27px;
          width: 7px;
          margin-left: 25px;
          margin-right: 20px;
        }
      }
      &-day,
      &-hour,
      &-minute {
        color: $color_grey;
        @include h2;
      }
      &-day-name,
      &-hour-name,
      &-minute-name {
        font-family: $roboto;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: $color_dark_grey_2;
      }
    }
    &-block-title {
      margin-top: 80px;
      margin-bottom: 30px;

      h3 {
        color: $color_black;
      }

      @media (max-width: $tablet_max) {
        margin-top: 77px;
      }
  
      @media (max-width: $phone-max) {
        margin-top: 30px;
      }
    }
    .btn-goto {
      @media (min-width: $desktop_min) {
        margin: 0;
      }
    }
  }
}

.user-content p {
  @media (min-width: $tablet_min) {
    height: 255px;
    margin-bottom: 30px;
  }

  @media (min-width: $desktop_min) {
    margin-bottom: 80px;
    margin-top: 0;
    height: auto;
  }
}
